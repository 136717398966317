import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  DatePicker,
  Button,
  Descriptions,
  ConfigProvider,
  Card,
  Flex,
  Progress,
  Table,
} from "antd";

import { FrownOutlined } from "@ant-design/icons";
import "./style.css";
import SectionComponent from "./SectionComponent";
import botService from "../service/botService";
const { Column } = Table;
const FeatureBot = () => {
  const [date, setDate] = useState({
    from: "",
    to: "",
  });
  const [loading, setLoading] = useState(true);
  const [botTrading, setBotTrading] = useState([]);
  const [transaction, setTransaction] = useState([]);
  useEffect(() => {
    const fetchBotTrading = async () => {
      setLoading(true); // Bắt đầu loading
      try {
        const data = await botService.getBotTrading();
        setBotTrading(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Kết thúc loading
      }
    };

    fetchBotTrading();
  }, []);
  useEffect(() => {
    const fetchTransaction = async () => {
      setLoading(true); // Bắt đầu loading
      try {
        const data = await botService.getTransaction();
        setTransaction(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Kết thúc loading
      }
    };

    fetchTransaction();
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 10,
      },
    },
  };
  const conicColors = {
    "0%": "#87d068",
    "50%": "#ffe58f",
    "100%": "#ffccc7",
  };
  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      fromDay: fieldsValue["fromDay"].format("YYYY-MM-DD"),
      toDay: fieldsValue["toDay"].format("YYYY-MM-DD"),
    };
    setDate({
      from: values.fromDay,
      to: values.toDay,
    });
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: "#bfbfc5",
          },
          Descriptions: {
            titleColor: "#25a09b",
          },
          Table: {
            headerBg: "#25a09b",
            headerColor: "white",
          },
        },
      }}
    >
      <SectionComponent text="Tính năng bot" />
      <Row
        gutter={[16, 16]}
        style={{ width: "80%", margin: "0 auto", textAlign: "center" }}
      >
        <Col xs={24} lg={8}>
          <Card style={{ height: "100%" }}>
            <Descriptions title="THÔNG TIN BOT" column={1}>
              <Descriptions.Item label="Tên bot">
                <span style={{ fontWeight: "bold" }}>
                  {botTrading.length > 0 ? botTrading[0].name : "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Thông tin">
                <span>
                  Khung TG 5 phút | Giá khớp lệnh: Giá mở cửa sau nến tín hiệu
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Thời gian Backtest">
                <span>
                  {date.from && date.to
                    ? `Từ ${date.from} đến ${date.to}`
                    : "-"}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card style={{ height: "100%" }}>
            <Descriptions title="THÔNG TIN CHI TIẾT" />
            <Form {...formItemLayout} onFinish={onFinish}>
              <Form.Item
                label="Từ ngày"
                name="fromDay"
                rules={[
                  { required: true, message: "Vui lòng chọn ngày bắt đầu!" },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Đến ngày"
                name="toDay"
                rules={[
                  { required: true, message: "Vui lòng chọn ngày kết thúc!" },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button htmlType="submit" className="backtest">
                  <span className="textbacktest">Backtest</span>
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card style={{ height: "100%" }}>
            <Descriptions title="TỈ LỆ THẮNG" />
            <Flex align="center" justify="center" gap="middle" wrap>
              <Progress
                size={150}
                type="circle"
                percent={botTrading.length > 0 ? botTrading[0].winRate : 0}
                strokeColor={conicColors}
              />
            </Flex>
          </Card>
        </Col>
      </Row>
      <SectionComponent text="Danh sách giao dịch" />
      <Row style={{ width: "80%", margin: "0 auto", textAlign: "center" }}>
        <Col xs={24}>
          <Table
            size="middle"
            scroll={{ x: true }}
            dataSource={transaction}
            rowKey={(record) => record.id} // Assuming each log entry has a unique id
            locale={{
              emptyText: (
                <React.Fragment>
                  <FrownOutlined style={{ fontSize: 32 }} />
                  <div>No Data</div>
                </React.Fragment>
              ),
            }}
            loading={loading}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              align="center"
              title="Thời gian"
              dataIndex="dateTime"
              key="dateTime"
            />
            <Column
              align="center"
              title="Loại lệnh"
              dataIndex="signal"
              key="signal"
              render={(text) => (
                <span
                  style={{
                    color: text === "LONG" ? "green" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {text}
                </span>
              )}
            />
            <Column
              align="center"
              title="Giá chốt"
              dataIndex="profitPointTP"
              key="profitPointTP"
            />
            <Column align="center" title="SL" dataIndex="isSL" key="isSL" />
            <Column
              align="center"
              title="Số hợp đồng"
              dataIndex="numberContract"
              key="numberContract"
            />
            <Column
              align="center"
              title="Giá khớp"
              dataIndex="priceBuy"
              key="priceBuy"
            />
            <Column
              align="center"
              title="Lợi nhuận"
              dataIndex="profit"
              key="profit"
            />
          </Table>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default FeatureBot;
