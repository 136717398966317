import React, { useState, useEffect } from "react";
import { Table, ConfigProvider, Select, DatePicker, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import { FrownOutlined } from "@ant-design/icons";
import SectionComponent from "./SectionComponent";
import userService from "../service/userService";
import authService from "../service/authService";
const { Column } = Table;
const { Option } = Select;

const HistoryComponent = () => {
  const [loading, setLoading] = useState(false);
  const [logHistory, setLogHistory] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [filterValues, setFilterValues] = useState({});
  const user = authService.getCurrentUser() ? authService.getCurrentUser() : {};

  const id = user.userId;
  const accessToken = user.access_token;

  const formatCurrency = (amount) => {
    const formattedAmount = amount.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });
    return formattedAmount.replace(/\s+₫$/, "");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let data;
        switch (filterType) {
          case "year":
            data = await userService.getLogHistoryYear(
              filterValues.year,
              id,
              accessToken
            );
            break;
          case "month":
            data = await userService.getLogHistoryMonth(
              filterValues.month,
              filterValues.year,
              id,
              accessToken
            );
            break;
          case "day":
            data = await userService.getLogHistoryDay(
              filterValues.day,
              filterValues.month,
              filterValues.year,
              id,
              accessToken
            );
            break;
          case "all":
          default:
            data = await userService.getLogHistory(id, accessToken);
            break;
        }

        if (data && data.logHistoryList && Array.isArray(data.logHistoryList)) {
          const processedData = data.logHistoryList.map((item) => {
            const formattedDate = moment(item.dateTime).format(
              "DD/MM/YYYY HH:mm:ss"
            );
            return {
              ...item,
              dateTime: formattedDate,
              isSL: item.isSL ? <CheckOutlined /> : "-",
              profit: formatCurrency(item.profit),
            };
          });
          setLogHistory(processedData);
        } else {
          console.error("API response is not in expected format", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Kết thúc loading, dù thành công hay thất bại
      }
    };

    if (id && accessToken) {
      fetchData();
    }
  }, [id, accessToken, filterType, filterValues]);

  const handleFilterChange = (value) => {
    setFilterType(value);
  };

  const handleFilterSubmit = (date) => {
    if (!date) {
      setFilterValues({});
      return;
    }

    const year = date.year();
    const month = date.month() + 1;
    const day = date.date();

    if (filterType === "day") {
      if (!year || !month) {
        console.error("Chọn năm và tháng trước khi chọn ngày.");
        return;
      }
      setFilterValues({
        day,
        month,
        year,
      });
    } else if (filterType === "month") {
      if (!year) {
        console.error("Chọn năm trước khi chọn tháng.");
        return;
      }
      setFilterValues({
        month,
        year,
      });
    } else if (filterType === "year") {
      setFilterValues({
        year,
      });
    } else {
      setFilterValues({});
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#25a09b",
            headerColor: "white",
          },
        },
      }}
    >
      <SectionComponent text="Lịch sử giao dịch" />

      <Space
        direction="vertical"
        style={{ width: "100%", marginBottom: "16px" }}
      >
        <Select
          defaultValue="all"
          onChange={handleFilterChange}
          style={{ width: 200 }}
        >
          <Option value="all">Tất cả</Option>
          <Option value="year">Theo năm</Option>
          <Option value="month">Theo tháng</Option>
          <Option value="day">Theo ngày</Option>
        </Select>

        {filterType === "year" && (
          <DatePicker
            picker="year"
            onChange={(date) => handleFilterSubmit(date)}
          />
        )}
        {filterType === "month" && (
          <DatePicker
            picker="month"
            onChange={(date) => handleFilterSubmit(date)}
          />
        )}
        {filterType === "day" && (
          <DatePicker onChange={(date) => handleFilterSubmit(date)} />
        )}
      </Space>

      <Table
        size="middle"
        scroll={{ x: true }}
        dataSource={logHistory}
        rowKey={(record) => record.id} // Assuming each log entry has a unique id
        locale={{
          emptyText: (
            <React.Fragment>
              <FrownOutlined style={{ fontSize: 32 }} />
              <div>No Data</div>
            </React.Fragment>
          ),
        }}
        loading={loading}
        pagination={{
          pageSize: 20,
        }}
      >
        <Column
          align="center"
          title="Thời gian"
          dataIndex="dateTime"
          key="dateTime"
        />
        <Column
          align="center"
          title="Loại lệnh"
          dataIndex="signal"
          key="signal"
          render={(text) => (
            <span
              style={{
                color: text === "LONG" ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {text}
            </span>
          )}
        />
        <Column
          align="center"
          title="Giá chốt"
          dataIndex="profitPointTP"
          key="profitPointTP"
        />
        <Column align="center" title="SL" dataIndex="isSL" key="isSL" />
        <Column
          align="center"
          title="Số hợp đồng"
          dataIndex="numberContract"
          key="numberContract"
        />
        <Column
          align="center"
          title="Giá khớp"
          dataIndex="priceBuy"
          key="priceBuy"
        />
        <Column
          align="center"
          title="Lợi nhuận"
          dataIndex="profit"
          key="profit"
        />
      </Table>
    </ConfigProvider>
  );
};

export default HistoryComponent;
