// Layout.js
import React from "react";
import HeaderComponent from "../Component/HeaderComponent";
import Footer from "../Component/FooterComponent";
const Layout = ({ children }) => {
  return (
    <div>
      <HeaderComponent />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
