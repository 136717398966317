import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL + "/api";

const getBotTrading = async () => {
  try {
    const res = await axios.get(API_URL + "/botTrading/getAll");
    return res.data; // Assuming res.data is an array of objects similar to courses
  } catch (error) {
    throw error;
  }
};

const getTransaction = async () => {
  const userId = "91db6119-f94f-4cc9-bf89-56ade1b0c1c3";
  try {
    const res = await axios.get(API_URL + `/getLogHistory/${userId}`);
    return res.data; // Assuming res.data is an array of objects similar to courses
  } catch (error) {
    throw error;
  }
};

const botService = {
  getBotTrading,
  getTransaction,
};

export default botService;
