import { FrownOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { Table, DatePicker, Select, Space } from "antd";
import moment from "moment";
import userService from "../service/userService";

const { Option } = Select;
const PurchaseHistory = ({ id, accessToken }) => {
  const [loading, setLoading] = useState(false);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterType, setFilterType] = useState("all");
  const [filterValues, setFilterValues] = useState({});

  const formatCurrency = (amount) => {
    const formattedAmount = amount.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });
    return formattedAmount.replace(/\s+₫$/, "");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let data;
        switch (filterType) {
          case "year":
            data = await userService.getPurchaseHistoryYear(
              filterValues.year,
              id,
              accessToken
            );
            break;
          case "month":
            data = await userService.getPurchaseHistoryMonth(
              filterValues.month,
              filterValues.year,
              id,
              accessToken
            );
            break;
          case "all":
          default:
            data = await userService.getPurchaseHistory(id, accessToken);
            break;
        }
        if (data && data.purchases && Array.isArray(data.purchases)) {
          const processedData = data.purchases.map((item) => ({
            ...item,
            key: item.id,
            Date: moment(item.date).format("DD/MM/YYYY"),
            StartDate: moment(item.startDate).format("DD/MM/YYYY"),
            EndDate: moment(item.endDate).format("DD/MM/YYYY"),
            PriceBot: formatCurrency(item.priceBot),
            PaymentMethod: item.paymentMethod,
            Status: item.status === "PAID" ?? "Đã thanh toán",
            Status_Package: moment().isAfter(moment(item.endDate))
              ? "Hết hạn"
              : "Đang sử dụng",
          }));
          setPurchaseHistory(processedData);
          setTotal(data.total || 0);
        } else {
          console.error("API response is not in expected format", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Kết thúc loading, dù thành công hay thất bại
      }
    };

    if (id && accessToken) {
      fetchData();
    }
  }, [id, accessToken, filterType, filterValues]);

  const handleFilterChange = (value) => {
    setFilterType(value);
    setFilterValues({});
  };

  const handleFilterSubmit = (date) => {
    if (!date) {
      setFilterValues({});
      return;
    }

    const year = date.year();
    const month = date.month() + 1;

    if (filterType === "year") {
      setFilterValues({ year });
    } else if (filterType === "month") {
      setFilterValues({ month, year });
    } else {
      setFilterValues({});
    }
  };

  const columns = [
    {
      title: "Ngày thanh toán",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Giá ",
      dataIndex: "PriceBot",
      key: "PriceBot",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "StartDate",
      key: "StartDate",
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "EndDate",
      key: "EndDate",
    },
    {
      title: "Phương thức thanh toán",
      dataIndex: "PaymentMethod",
      key: "PaymentMethod",
    },
    {
      title: "Trạng thái thanh toán",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Trạng thái gói",
      dataIndex: "Status_Package",
      key: "Status_Package",
    },
  ];

  return (
    <div>
      <Space
        direction="vertical"
        style={{ width: "90%", marginBottom: "16px" }}
      >
        <Select
          defaultValue="all"
          onChange={handleFilterChange}
          style={{ width: 200 }}
        >
          <Option value="all">Tất cả</Option>
          <Option value="year">Theo năm</Option>
          <Option value="month">Theo tháng</Option>
        </Select>
        {filterType === "year" && (
          <DatePicker
            picker="year"
            onChange={(date) => handleFilterSubmit(date)}
          />
        )}
        {filterType === "month" && (
          <DatePicker
            picker="month"
            onChange={(date) => handleFilterSubmit(date)}
          />
        )}
      </Space>
      <Table
        size="middle"
        dataSource={purchaseHistory}
        columns={columns}
        rowKey={(record) => record.id} // Assuming each log entry has a unique id
        locale={{
          emptyText: (
            <React.Fragment>
              <FrownOutlined style={{ fontSize: 32 }} />
              <div>No Data</div>
            </React.Fragment>
          ),
        }}
        scroll={{ x: true }}
        loading={loading}
        pagination={{
          pageSize: 10,
        }}
      />
      <p
        style={{
          marginTop: "1rem",
          textAlign: "right",
          fontWeight: "bold",
        }}
      >
        Tổng thanh toán: {formatCurrency(total)} VND
      </p>
    </div>
  );
};

export default PurchaseHistory;
