import React from "react";
import { Helmet } from "react-helmet";
import InfoUserComponent from "../../Component/InfoUserComponent";
import LayoutComponent from "../Layout";

const Info = () => {
  return (
    <LayoutComponent>
      <Helmet>
        <title>Thông Tin tài khoản | autobotps.com</title>
        <meta
          name="description"
          content="Xem thông tin chi tiết của người dùng và lịch sử thanh toán, lợi nhuận."
        />
        <meta
          name="keywords"
          content="thông tin người dùng, lịch sử mua, lợi nhuận, autobotps"
        />
      </Helmet>
      <InfoUserComponent />
    </LayoutComponent>
  );
};

export default Info;
