import axios from 'axios'
import authService from './authService'
// const API_URL = "https://minhnhat27.id.vn/api";

const API_URL = process.env.REACT_APP_BASE_URL + '/api'

const API_URL_PAYMENT = process.env.REACT_APP_BASE_URL + '/api/payments'

// Giá bot
const getPriceBots = async () => {
  try {
    const res = await axios.get(API_URL + '/priceBot/getAll')
    return res.data
  } catch (error) {
    throw error
  }
}

const checkPayment = async (id) => {
  try {
    const res = await axios.get(API_URL_PAYMENT + `/check-payment/${id}`)
    return res.data
  } catch (error) {
    throw error
  }
}
// fetch với accessToken
const fetchWithAuth = async (url, params, accessToken) => {
  try {
    const res = await axios.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const user = authService.getCurrentUser()
      if (user && user.refresh_token) {
        try {
          const newAccessToken = await authService.refreshToken({
            refresh_token: user.refresh_token,
          })
          if (newAccessToken) {
            authService.setUserToken(newAccessToken)
            const res = await axios.get(url, {
              params,
              headers: {
                Authorization: `Bearer ${newAccessToken}`,
              },
            })
            return res.data
          }
        } catch (refreshError) {
          throw refreshError
        }
      }
    }
    throw error
  }
}

//Lịch sử giao dịch All
const getLogHistory = (userId, accessToken) => {
  return fetchWithAuth(API_URL + '/logHistory/getLogHistoryAll', { userId }, accessToken)
}

//Lịch sử giao dịch Năm
const getLogHistoryYear = (year, userId, accessToken) => {
  return fetchWithAuth(API_URL + '/logHistory/getLogHistoryYear', { year, userId }, accessToken)
}

//Lịch sử giao dịch Tháng
const getLogHistoryMonth = (month, year, userId, accessToken) => {
  return fetchWithAuth(
    API_URL + '/logHistory/getLogHistoryMonth',
    { month, year, userId },
    accessToken,
  )
}

//Lịch sử giao dịch Ngày
const getLogHistoryDay = (day, month, year, userId, accessToken) => {
  return fetchWithAuth(
    API_URL + '/logHistory/getLogHistoryDay',
    { day, month, year, userId },
    accessToken,
  )
}

//Tạo thanh toán
const createPaymentLink = async (userId, month, botTradingId, cancelUrl, returnUrl) => {
  const response = await axios.post(`${API_URL_PAYMENT}/create-payment-link`, {
    botTradingId: botTradingId,
    userId: userId,
    month: month,
    cancelUrl: cancelUrl,
    returnUrl: returnUrl,
  })
  return response.data
}

//Lịch sử mua all
const getPurchaseHistory = (userId, accessToken) => {
  return fetchWithAuth(API_URL + '/purchaseHistory/getPurchaseAllByUser', { userId }, accessToken)
}

//Lịch sử mua theo tháng
const getPurchaseHistoryMonth = (month, year, userId, accessToken) => {
  return fetchWithAuth(
    API_URL + '/purchaseHistory/getPurchaseMonthByUser',
    { userId, month, year },
    accessToken,
  )
}

//Lịch sử mua theo năm
const getPurchaseHistoryYear = (year, userId, accessToken) => {
  return fetchWithAuth(
    API_URL + '/purchaseHistory/getPurchaseYearByUser',
    { userId, year },
    accessToken,
  )
}

//Lợi nhuận All/profitLoss/GetProfitLossAll
const getProfitLossAll = async (user, accessToken) => {
  return fetchWithAuth(API_URL + '/profitLoss/GetProfitLossAll', { user }, accessToken)
}

const getProfitLossYear = async (year, user, accessToken) => {
  return fetchWithAuth(API_URL + '/profitLoss/GetProfitLossYear', { year, user }, accessToken)
}

const getProfitLossMonth = async (month, year, user, accessToken) => {
  return fetchWithAuth(
    API_URL + '/profitLoss/GetProfitLossMoth',
    { month, year, user },
    accessToken,
  )
}

const getProfitLossDay = async (day, month, year, user, accessToken) => {
  return fetchWithAuth(
    API_URL + '/profitLoss/GetProfitLossDay',
    { day, month, year, user },
    accessToken,
  )
}
const userService = {
  getPriceBots,
  getLogHistory,
  createPaymentLink,
  getPurchaseHistory,
  getLogHistoryYear,
  getLogHistoryMonth,
  getLogHistoryDay,
  getPurchaseHistoryMonth,
  getPurchaseHistoryYear,
  getProfitLossAll,
  getProfitLossYear,
  getProfitLossMonth,
  getProfitLossDay,
  checkPayment,
}

export default userService
