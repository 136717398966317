import React, { useState, useEffect } from "react";
import { MenuOutlined, UserOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Row, Col, Avatar, Dropdown, Drawer } from "antd";
import "./style.css"; // Đảm bảo bao gồm tệp CSS của bạn
import { Link } from "react-router-dom";
import { useAuth } from "../App";
import authService from "../service/authService";
import authActions from "../service/authAction";

const HeaderComponent = () => {
  const { state, dispatch } = useAuth();
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const user = authService.getCurrentUser();
    setUsername(user ? user.name : "");
  }, [state.isAuthenticated]);

  const handleLogout = () => {
    dispatch(authActions.LOGOUT);
    authService.logout();
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setOpen(false);
      setIsClosing(false);
    }, 300);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const items = [
    {
      key: "1",
      label: <Link to="/info">Thông tin tài khoản</Link>,
    },
    {
      key: "2",
      label: <Link to="/history">Lịch sử giao dịch</Link>,
    },
    {
      key: "3",
      label: <div onClick={handleLogout}>Đăng xuất</div>,
    },
  ];

  const items2 = [
    {
      key: "1",
      label: <Link to="/login">Đăng Nhập</Link>,
    },
    {
      key: "2",
      label: <Link to="/register">Đăng ký</Link>,
    },
  ];

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1000);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigationItems = (
    <ul
      className={`custom-menu ${isMobile ? "hidden" : "horizontal"} ${
        isMobile && open ? "vertical" : "hidden"
      }`}
    >
      <li>
        <Link to="/">Trang chủ</Link>
      </li>
      <li>
        <Link to="/about">Giới thiệu</Link>
      </li>
      <li>
        <Link to="/backtest">Backtest</Link>
      </li>
      <li>
        <Link to="/price">Bảng giá dịch vụ</Link>
      </li>
      <li>
        <a download href="https://autobotps.com/v1/ext.rar">
          Tải Extension
        </a>
      </li>
      <li>
        <Dropdown
          menu={{
            items: state.isAuthenticated ? items : items2,
          }}
          placement="bottom"
          arrow
        >
          <div className="user-content" style={{ marginLeft: 10 }}>
            <Avatar size="large" icon={<UserOutlined />} />
            {state.isAuthenticated && (
              <span className="user-name">{username}</span>
            )}
          </div>
        </Dropdown>
      </li>
    </ul>
  );

  return (
    <>
      <Row
        align="middle"
        style={{
          width: "100%",
          backgroundColor: "#094442",
          padding: "10px 0",
          minHeight: "80px",
        }}
      >
        <Col offset={1}>
          <Link to="/">
            <img src="/images/logo.png" alt="Logo" className="logo" />
          </Link>
        </Col>
        <Col flex="auto">
          {isMobile ? (
            <div
              style={{
                position: "absolute",
                right: "20px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <Button
                type="primary"
                icon={<MenuOutlined />}
                onClick={showDrawer}
                className="bg-transparent border border-white text-white "
              />
            </div>
          ) : null}
          {isMobile && open ? (
            <Drawer
              onClose={onClose}
              open={open}
              width="70%"
              bodyStyle={{ backgroundColor: "#094442", color: "#ffffff" }}
              headerStyle={{ backgroundColor: "#094442", color: "#ffffff" }}
              closeIcon={<CloseOutlined className="text-white" />}
              className={`${
                isClosing ? "drawer-slide-in" : "drawer-slide-out"
              }`}
            >
              {navigationItems}
            </Drawer>
          ) : (
            navigationItems
          )}
        </Col>
      </Row>
    </>
  );
};

export default HeaderComponent;
