import React from "react";
import FeatureBot from "../../Component/FeatureBot";
import LayoutComponent from "../Layout";
import { Helmet } from "react-helmet";

const Backtest = () => {
  return (
    <LayoutComponent>
      <Helmet>
        <title>Tính Năng Bot - Backtest | autobotps.com</title>
        <meta name="description" content="Trang tính năng bot backtest để kiểm tra hiệu suất của bot trading. Tùy chỉnh thời gian backtest và xem tỉ lệ thắng của bot." />
      </Helmet>
      <FeatureBot />
    </LayoutComponent>
  );
};

export default Backtest;
