import React, { useState, useEffect } from "react";
import { Row, Col, Table, ConfigProvider } from "antd";
import {
  FallOutlined,
  GlobalOutlined,
  CustomerServiceOutlined,
  StockOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./style.css";
import SectionComponent from "./SectionComponent";
import CardComponent from "./CardComponent";
import botService from "../service/botService";

const AboutComponent = () => {
  const [loading, setLoading] = useState(true);
  const [botTrading, setBotTrading] = useState([]);

  useEffect(() => {
    const fetchBotTrading = async () => {
      setLoading(true); // Bắt đầu loading
      try {
        const data = await botService.getBotTrading();
        setBotTrading(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Kết thúc loading
      }
    };

    fetchBotTrading();
  }, []);

  const columns = [
    {
      title: "Tên Bot",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Số lệnh",
      dataIndex: "commandNumber",
      key: "commandNumber",
    },
    {
      title: "Lợi nhuận",
      dataIndex: "profit",
      key: "profit",
    },
    {
      title: "Tỉ lệ thắng",
      dataIndex: "winRate",
      key: "winRate",
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#25a09b",
            headerColor: "white",
          },
        },
      }}
    >
      <Row
        gutter={[16, 16]}
        style={{
          width: "80%",
          margin: "0 auto",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Col xs={24}>
          <SectionComponent text="Chào Mừng Bạn Đến Với Bot Đầu Tư Chứng Khoán" />
        </Col>
        <Col xs={24}>
          <CardComponent
            description={
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={14}>
                  <h1
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      textAlign: "left",
                    }}
                  >
                    Trong thời đại công nghệ 4.0, việc ứng dụng trí tuệ nhân tạo
                    (AI) và máy học (machine learning) vào đầu tư chứng khoán đã
                    trở nên phổ biến và đem lại nhiều lợi ích đáng kể cho nhà
                    đầu tư. Bot Đầu Tư Chứng Khoán là một công cụ mạnh mẽ giúp
                    bạn tối ưu hóa danh mục đầu tư và gia tăng lợi nhuận với sự
                    hỗ trợ của công nghệ hiện đại nhất, đặc biệt được phát triển
                    trên nền tảng Amibroker.
                  </h1>
                </Col>
                <Col xs={24} lg={10}>
                  <img
                    src="https://timo.vn/wp-content/uploads/so-giao-dich-chung-khoan-la-gi.png"
                    alt="Hình ảnh minh họa về giao dịch chứng khoán"
                  />
                </Col>
              </Row>
            }
          />
        </Col>
        <Col xs={24}>
          <SectionComponent text="Tại Sao Chọn Bot Đầu Tư Chứng Khoán?" />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <CardComponent
            icon={<StockOutlined className="icon_feature" />}
            title="Phân Tích Dữ Liệu Nhanh Chóng và Chính Xác"
            description="Sử dụng Amibroker, một trong những nền tảng phân tích kỹ thuật hàng đầu, Bot tận dụng các thuật toán tiên tiến để phân tích hàng triệu điểm dữ liệu từ thị trường chứng khoán toàn cầu. Điều này giúp phát hiện các xu hướng và cơ hội đầu tư mà con người có thể bỏ lỡ."
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <CardComponent
            icon={<GlobalOutlined className="icon_feature" />}
            title="Giao Dịch Tự Động và Linh Hoạt"
            description="Bot có khả năng thực hiện giao dịch tự động dựa trên các chiến lược đã được lập trình sẵn hoặc tùy chỉnh theo nhu cầu của bạn, được lập trình trong Amibroker AFL (Amibroker Formula Language). Điều này đảm bảo rằng bạn không bỏ lỡ bất kỳ cơ hội nào, ngay cả khi bạn đang bận rộn."
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <CardComponent
            icon={<FallOutlined className="icon_feature" />}
            title="Giảm Thiểu Rủi Ro"
            description="Với khả năng phân tích rủi ro và quản lý danh mục đầu tư một cách hiệu quả, Bot giúp bạn giảm thiểu rủi ro và bảo vệ lợi nhuận của mình."
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <CardComponent
            icon={<CustomerServiceOutlined className="icon_feature" />}
            title="Hỗ Trợ 24/7"
            description="Bot hoạt động liên tục 24/7, giúp bạn luôn nắm bắt được mọi biến động của thị trường. Bạn có thể yên tâm rằng danh mục đầu tư của mình luôn được theo dõi và điều chỉnh kịp thời."
          />
        </Col>
        <Col xs={24}>
          <SectionComponent text="Thông Số Lệnh Bot" />
        </Col>
        <Col xs={24} md={16}>
          <Table
            locale={{
              emptyText: (
                <React.Fragment>
                  <FrownOutlined style={{ fontSize: 32 }} />
                  <div>Không Có Dữ Liệu</div>
                </React.Fragment>
              ),
            }}
            columns={columns}
            dataSource={botTrading}
            rowKey={(record) => record.id}
            loading={loading}
          />
        </Col>
        <Col xs={24} style={{ marginTop: 50, marginBottom: 50 }}>
          <Link to="/price" className="button_signup">
            Đăng Ký Ngay
          </Link>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default AboutComponent;
