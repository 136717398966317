import React from "react";
import AboutComponent from "../../Component/AboutComponent";
import LayoutComponent from "../Layout";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <LayoutComponent>
      <Helmet>
        <title>Về Chúng Tôi - Cho Thuê Bot Chứng Khoán Phái Sinh</title>
        <meta name="description" content="Tìm hiểu về dịch vụ cho thuê bot chứng khoán phái sinh của chúng tôi và cách mà chúng tôi hỗ trợ bạn trong đầu tư chứng khoán." />
        <meta name="keywords" content="bot chứng khoán, về chúng tôi, phái sinh, giao dịch tự động" />
      </Helmet>
      <AboutComponent />
    </LayoutComponent>
  );
};

export default About;
