import React, { useEffect, useState } from "react";
import { Descriptions, ConfigProvider, Row, Col } from "antd";
import SectionComponent from "./SectionComponent";
import ProfitLoss from "./ProfitLoss";

import authService from "../service/authService";

import PurchaseHistory from "./PurchaseHistory";
import { useLocation } from "react-router-dom";
import { useNotification } from "../App";
import userService from "../service/userService";

const InfoUserComponent = () => {
  const location = useLocation();
  const { notify } = useNotification();
  useEffect(() => {
    const isAction = location.search.includes("isAction=true");
    setIsShowModel(isAction);
    if(isAction){
      const userId = authService.getCurrentUser()?.userId;
      userService.checkPayment(userId);
    }
  },[location.search])

  const [isShowModel, setIsShowModel] = useState(false);

  const user = authService.getCurrentUser() ? authService.getCurrentUser() : {};
  const id = user.userId;
  const accessToken = user.access_token;
  const items = [
    {
      key: "1",
      label: "Tên",
      children: <p>{user.name}</p>,
      span: 3,
    },
    {
      key: "2",
      label: "Số điện thoại",
      children: <p>{user.phoneNumber}</p>,
      span: 3,
    },
    {
      key: "3",
      label: "Email",
      children: <p>{user.email}</p>,
    },
  ];
  return (
    <>
    {isShowModel && notify.open({
        message: `Message`,
        type: "success",
        description:
          "Thanh toán thành công, vui lòng kiểm tra lại thông tin tài khoản của bạn!",
      })}
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#25a09b",
            headerColor: "white",
          },
        },
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row gutter={[16, 16]} style={{ width: "90%" }}>
          <Col md={12} xs={24}>
            <SectionComponent text="Thông tin tài khoản" />
            <Descriptions column={3} items={items} />
          </Col>
          <Col md={12} xs={24}>
            <SectionComponent text="Lợi nhuận" />
            <ProfitLoss id={id} accessToken={accessToken} />
          </Col>
          <Col xs={24}>
            <SectionComponent text="Gói dịch vụ" />
            <PurchaseHistory id={id} accessToken={accessToken} />
          </Col>
        </Row>
      </div>
    </ConfigProvider>
    </>
  );
};

export default InfoUserComponent;
