import React from "react";
import tablePrice from "../../Component/TablePrice";
import Feature from "../../Component/Feature";
import BannerComponent from "../../Component/BannerComponent";
import LayoutComponent from "../Layout";
import { Helmet } from "react-helmet";
const TablePrice = tablePrice;

const HomePage = () => {
  return (
    <LayoutComponent>
      <Helmet>
        <title>Trang chủ - Cho thuê bot chứng khoán phái sinh</title>
        <meta name="description" content="Dịch vụ cho thuê bot chứng khoán phái sinh chuyên nghiệp và hiệu quả. Thông tin các gói dịch vụ" />
        <meta name="keywords" content="bot chứng khoán, cho thuê bot, phái sinh, giao dịch tự động" />
      </Helmet>
      <BannerComponent />
      <Feature />
      <TablePrice />
    </LayoutComponent>
  );
};

export default HomePage;
