import React from "react";
import { Helmet } from "react-helmet";
import TablePrice from "../../Component/TablePrice";
import LayoutComponent from "../Layout";

const ServicePrice = () => {
  return (
    <LayoutComponent>
      <Helmet>
        <title>Bảng Giá Dịch Vụ | autobotps.com</title>
        <meta
          name="description"
          content="Trang bảng giá dịch vụ cho các gói bot trading với nhiều ưu đãi hấp dẫn. Mua ngay để nhận ưu đãi tốt nhất!"
        />
        <meta
          name="keywords"
          content="bot trading, bảng giá, dịch vụ, khuyến mãi, mua ngay"
        />
      </Helmet>
      <TablePrice />
    </LayoutComponent>
  );
};

export default ServicePrice;
