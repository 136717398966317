import { FrownOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { ConfigProvider, Table, DatePicker, Select, Space } from "antd";
import userService from "../service/userService";
import moment from "moment";
const { Option } = Select;

const ProfitLoss = ({ id, accessToken }) => {
  const [loading, setLoading] = useState(false);

  const [profitLoss, setProfitLoss] = useState([]);
  const [total, setTotal] = useState(0);

  const [filterType, setFilterType] = useState("all");
  const [filterValues, setFilterValues] = useState({});

  const formatCurrency = (amount) => {
    const formattedAmount = amount.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });
    return formattedAmount.replace(/\s+₫$/, "");
  };
  console.log(profitLoss);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let data;
        switch (filterType) {
          case "year":
            data = await userService.getProfitLossYear(
              filterValues.year,
              id,
              accessToken
            );
            break;
          case "month":
            data = await userService.getProfitLossMonth(
              filterValues.month,
              filterValues.year,
              id,
              accessToken
            );
            break;
          case "day":
            data = await userService.getProfitLossDay(
              filterValues.day,
              filterValues.month,
              filterValues.year,
              id,
              accessToken
            );
            break;
          case "all":
          default:
            data = await userService.getProfitLossAll(id, accessToken);
            break;
        }

        if (
          data &&
          data.profitLossDTOList &&
          Array.isArray(data.profitLossDTOList)
        ) {
          const processedData = data.profitLossDTOList.map((item) => {
            const formattedDate = moment(item.date).format(
              "DD/MM/YYYY HH:mm:ss"
            );
            return {
              ...item,
              date: formattedDate,
              price: formatCurrency(item.price),
            };
          });
          setProfitLoss(processedData);
          setTotal(data.total || 0);
        } else {
          console.error("API response is not in expected format", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id && accessToken) {
      fetchData();
    }
  }, [id, accessToken, filterType, filterValues]);

  const handleFilterChange = (value) => {
    setFilterType(value);
    setFilterValues({});
  };

  const handleFilterSubmit = (date) => {
    if (!date) {
      setFilterValues({});
      return;
    }

    const year = date.year();
    const month = date.month() + 1;
    const day = date.date();

    if (filterType === "day") {
      setFilterValues({
        day,
        month,
        year,
      });
    } else if (filterType === "month") {
      setFilterValues({
        month,
        year,
      });
    } else if (filterType === "year") {
      setFilterValues({
        year,
      });
    } else {
      setFilterValues({});
    }
  };

  const columns = [
    {
      title: "Ngày",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Giá",
      dataIndex: "price",
      key: "price",
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#25a09b",
            headerColor: "white",
          },
        },
      }}
    >
      <Space
        direction="vertical"
        style={{ width: "90%", marginBottom: "16px" }}
      >
        <Select
          defaultValue="all"
          onChange={handleFilterChange}
          style={{ width: 200 }}
        >
          <Option value="all">Tất cả</Option>
          <Option value="year">Theo năm</Option>
          <Option value="month">Theo tháng</Option>
          <Option value="day">Theo ngày</Option>
        </Select>

        {filterType === "year" && (
          <DatePicker
            picker="year"
            onChange={(date) => handleFilterSubmit(date)}
          />
        )}
        {filterType === "month" && (
          <DatePicker
            picker="month"
            onChange={(date) => handleFilterSubmit(date)}
          />
        )}
        {filterType === "day" && (
          <DatePicker onChange={(date) => handleFilterSubmit(date)} />
        )}
      </Space>
      <Table
        size="small"
        style={{ width: "100%" }}
        dataSource={profitLoss}
        columns={columns}
        rowKey={(record) => record.id} // Assuming each log entry has a unique id
        locale={{
          emptyText: (
            <React.Fragment>
              <FrownOutlined style={{ fontSize: 32 }} />
              <div>No Data</div>
            </React.Fragment>
          ),
        }}
        scroll={{ x: true }}
        loading={loading}
        pagination={{
          pageSize: 10,
        }}
      />

      <p
        style={{
          marginTop: "1rem",
          textAlign: "right",
          fontWeight: "bold",
        }}
      >
        Tổng lợi nhuận: {formatCurrency(total)} VND
      </p>
    </ConfigProvider>
  );
};

export default ProfitLoss;
