import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { message, notification } from 'antd';
import './App.css';
import HomePage from './Page/Home';
import Login from './Page/Login';
import Register from './Page/Register';
import Backtest from './Page/Backtest';
import ServicePrice from './Page/ServicePrice';
import About from './Page/About';
import ForgetPass from './Page/ForgetPass';
import NewPassword from './Page/NewPassword';
import { Info, LogHistory } from './Page/User';
import { initialState, reducer } from './service/authReducer';
import authService from './service/authService';

const NotificationContext = createContext();
export const useNotification = () => useContext(NotificationContext);

const MessageContext = createContext();
export const useMessage = () => useContext(MessageContext);

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [notify, contextHolder] = notification.useNotification();
  const [antMessage, contextMessage] = message.useMessage();

  useEffect(() => {
    if (state.isAuthenticated) {
      const user = authService.getCurrentUser();
      const data = { refresh_token: user.refresh_token };
      authService
        .refreshToken(data)
        .then((res) => authService.setUserToken(res.data?.access_token));
      setInterval(() => {
        authService
          .refreshToken(data)
          .then((res) => authService.setUserToken(res.data?.access_token));
      }, 300000);
    }
  }, [state.isAuthenticated]);

  return (
    <MessageContext.Provider value={{ antMessage }}>
      {contextMessage}
      <NotificationContext.Provider value={{ notify }}>
        {contextHolder}
        <AuthContext.Provider value={{ state, dispatch }}>
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/backtest" element={<Backtest />} />
              <Route path="/price" element={<ServicePrice />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgetPass" element={<ForgetPass />} />
              <Route path="/newPass" element={<NewPassword />} />
              <Route path="/history" element={<LogHistory />} />
              <Route path="/info" element={<Info />} />
            </Routes>
          </Router>
        </AuthContext.Provider>
      </NotificationContext.Provider>
    </MessageContext.Provider>
  );
}

export default App;
