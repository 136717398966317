/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { PhoneOutlined } from "@ant-design/icons";
import icon from "../asset/image/logo.png";

const FooterComponent = () => {
  return (
    <footer className="bg-[#063332] dark:bg-gray-900 mt-8">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
          <div className="w-full md:w-1/2 lg:w-1/3 mb-6 md:mb-0">
            <h1 className="mb-6 text-sm font-semibold text-[#78A083] uppercase dark:text-white">
              Liên hệ
            </h1>
            <ul className="text-[#78A083] dark:text-gray-400 font-medium">
              <li className="mb-4 ">Tên: Lý Đức Minh</li>
              <li className="mb-4 ">
                <PhoneOutlined />/ Zalo: 0936793913
              </li>
              <li>Hỗ trợ: Thứ 2 - Thứ 7 ( 8:00 - 19:30)</li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 flex justify-center md:justify-end">
            <a href="/" className="flex items-center">
              <img src={icon} className="h-10 me-3" alt="FlowBite Logo" />
              <span className="self-center text-2xl text-[#78A083] font-semibold whitespace-nowrap dark:text-white">
                TradingBot™
              </span>
            </a>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <span className="text-sm text-white text-center dark:text-gray-400">
            © 2024{" "}
            <a href="/" className="hover:underline">
              TradingBot™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
