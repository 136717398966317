import React from "react";
import { Row, Col } from "antd";
import { Helmet } from "react-helmet";
import HistoryComponent from "../../Component/HistoryComponent";
import LayoutComponent from "../Layout";

const LogHistory = () => {
  return (
    <LayoutComponent>
      <Helmet>
        <title>Lịch sử giao dịch | autobotps.com</title>
        <meta
          name="description"
          content="Xem lịch sử giao dịch của bạn trên autobotps.com."
        />
        <meta name="keywords" content="lịch sử, ghi chép, giao dịch" />
      </Helmet>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "90%" }}>
          <Col xs={24}>
            <HistoryComponent />
          </Col>
        </Row>
      </div>
    </LayoutComponent>
  );
};

export default LogHistory;
