import React from "react";
import { Button, Space, Descriptions, Row, Col } from "antd";
import authService from "../service/authService";
import "./style.css";
import { Link } from "react-router-dom";

const BannerComponent = () => {
  const userInfo = authService.getCurrentUser() || {};
  return (
    <Row className="header_background_image">
      <div className="hexagon">
        <Col span={14}>
          <Descriptions style={{display: 'flex', marginTop: "30%", marginLeft: "10%"}}>
            <Descriptions.Item span={3}>
              <span
                style={{
                  color: "white",
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                HIỆU SUẤT ĐẦU TƯ CAO
              </span>
            </Descriptions.Item>
            <Descriptions.Item span={2}>
              <span style={{ color: "white", fontSize: "1.1rem" }}>
                Tự động báo điểm mua bán phái sinh
              </span>
            </Descriptions.Item>
            <Descriptions.Item span={3} />
            {!userInfo.userId && (
              <Descriptions.Item span={3}>
                <Space size={50} wrap>
                  <Link to="/login">
                    <Button className="signin_signup signin">
                      <span>Đăng nhập</span>
                    </Button>
                  </Link>
                  <Link to="/register">
                    <Button className="signin_signup signup">
                      <span>Đăng Ký</span>
                    </Button>
                  </Link>
                </Space>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Col>
      </div>
    </Row>
  );
};

export default BannerComponent;
