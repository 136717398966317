export const LOGIN = {
  type: 'LOGIN',
}

export const LOGOUT = {
  type: 'LOGOUT',
}

const authActions = {
  LOGIN,
  LOGOUT,
}
export default authActions
