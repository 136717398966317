/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import buynow from "../asset/image/logo.png";
import SectionComponent from "./SectionComponent";
import authService from "../service/authService";
import userService from "../service/userService";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import "./style.css";

const TablePrice = () => {
  const userInfo = authService.getCurrentUser() || {};
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await userService.getPriceBots();
        setCourses(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleBuyNow = async (course) => {
    setLoading(true);
    if (!userInfo.userId) {
      navigate("/login");
    }
    const botTradingId = course.botTradingId;
    const month = course.month;
    const userId = userInfo.userId;
    const cancelUrl = window.location.href;
    const returnUrl = `${window.location.origin}/info?isAction=true`;
    try {
      userService
        .createPaymentLink(userId, month, botTradingId, cancelUrl, returnUrl)
        .then((res) => {
          window.location.href = res;
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.error("Error creating payment link:", error);
      setLoading(false);
    }
  };

  const formatCurrency = (amount) => {
    const formattedAmount = amount.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });

    return formattedAmount.replace(/\s+₫$/, "");
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  return (
    <div>
      {loading && <Spin fullscreen />}
      <SectionComponent text="Bảng giá dịch vụ" />
      {isBannerVisible && (
        <div className="relative overflow-hidden w-full bg-gray-200 text-center py-5">
          <div className="whitespace-nowrap animate-marquee">
            <span className="text-lg font-bold text-[#094442]">
              Khuyến mãi đặc biệt! Giảm giá sốc 50% từ ngày 09/07/2024 đến ngày
              31/07/2024
            </span>
          </div>
          <button
            className="absolute top-2 right-2 text-sm font-semibold py-1 px-2 rounded-full z-30"
            onClick={handleCloseBanner}
          >
            X
          </button>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {courses.map((course) => (
          <div
            onClick={() => handleBuyNow(course)}
            key={course.botTradingId}
            className="courses_inner m-2 group px-10 py-5 bg-white/10 rounded-lg flex flex-col items-center justify-center gap-2 relative overflow-hidden cursor-pointer transition-all duration-300 hover:transition-all hover:duration-300 after:absolute after:h-full after:bg-[#abd373] after:-z-20 after:w-full after:inset-0 after:rounded-lg after:transition-all after:duration-500 after:hover:transition-all after:hover:duration-500 after:-translate-y-full after:hover:translate-y-0"
          >
            {course.discount > 0 && (
              <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-semibold py-1 px-2 rounded-full z-30">
                -{course.discount}%
              </span>
            )}
            <img
              src={course.image ? course.image : buynow}
              alt="icon"
              className="w-44 aspect-square text-[#abd373] group-hover:bg-gray-800 text-5xl rounded-full p-2 transition-all duration-300 group-hover:transition-all group-hover:duration-300 group-hover:translate-y-2 mx-auto"
            />
            <p className="font-semibold text-gray-200 tracking-wider group-hover:text-gray-700 text-xl">
              Gói {course.month} tháng
            </p>
            <p className="font-semibold text-gray-600 text-xs">
              {course.description}
            </p>
            <div className="flex flex-row justify-between items-center w-full">
              <p className="flex flex-wrap text-[#abd373] font-semibold group-hover:text-[white] transition-all duration-500  w-2/3 overflow-hidden text-ellipsis">
                <span className="line-through text-[#343a40]">
                  {formatCurrency(course.price)}
                </span>
                <span>
                  /
                  {formatCurrency(
                    course.price - (course.price * course.discount) / 100
                  )}
                </span>
              </p>
              <div className="btun4 lg:inline-flex items-center gap-3 bg-[#abd373] shadow-[10px_10px_150px_#ff9f0d] cursor-pointer py-2 px-4 text-sm font-semibold rounded-full transition-all duration-300 group-hover:bg-[#094442] group-hover:text-white transform transition-transform hover:scale-95">
                <button onClick={() => handleBuyNow(course)}>Mua ngay</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TablePrice;
